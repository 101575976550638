import React from 'react'
import Layout from '../../layouts'
import Article from '../../components/Article'
import SEO from '../../components/SEO'
import Container from '../../components/Container'
import Disqus from '../../components/Disqus'
import MobileNav from '../../components/MobileNav'
import './nav-blog-post.scss'

class NavBlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stickTop: false,
      stickBottom: false
    }

    this.sidebarRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('DOMContentLoaded', this.onContentLoaded)
    document.addEventListener('scroll', this.onScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('DOMContentLoaded', this.onContentLoaded)
    document.removeEventListener('scroll', this.onScroll)
  }

  onContentLoaded = (e) => {
    this.stickSidebar(e)    
  }

  onScroll = (e) => {
    this.stickSidebar(e)
  }

  stickSidebar = (e) => {
    const currentScrollY = window.pageYOffset
    let stickTop = false
    let stickBottom = false

    if(currentScrollY > 65) {
      stickTop = true

      if (document.documentElement.scrollHeight - (document.documentElement.scrollTop + document.documentElement.clientHeight) < 250) {
        stickBottom = true
      }
    }
    
    this.setState({
      stickTop: stickTop,
      stickBottom: stickBottom,
    })
  }

  render () {
    const { post, location, siteMetadata, navTitle, navList } = this.props

    return (
      <Layout
        location={location}
        siteMeta={siteMetadata}
        navList={navList} 
        navGroup={post.frontmatter.group}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={post.frontmatter.image ? post.frontmatter.image.publicURL: null}
          pathname={post.fields.slug}
          article={true}
        />
        <main className="nav-blog-main-content">
          <Container>
            <div className={`nav-sidebar-container ${this.state.stickTop ? 'stick-top': ''} ${this.state.stickBottom ? 'stick-bottom': ''}`} ref={this.sidebarRef}>
              <MobileNav location={location} navTitle={navTitle} navList={navList} navGroup={post.frontmatter.group} />
            </div>
            <div className="primary-content has-nav-sidebar">
              <Container>
                <Article
                  post={post}
                  siteMeta={siteMetadata}
                />
                <Disqus
                  location={location}
                  disqusShortname={
                    siteMetadata.disqusShortname
                  }
                />
              </Container>
            </div>
          </Container>
        </main>
      </Layout>
    )
  }
}

export default NavBlogPostTemplate