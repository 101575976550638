import React from 'react'
import { graphql } from 'gatsby'
import startCase from 'lodash/startCase'
import NavBlogPostTemplate from './nav-blog-post'

class CategoryBlogPost extends React.Component {
  render () {
    const post = this.props.data.mdx
    const groups = this.props.data.groupsMdx.group

    const navList = []

    groups.forEach(group => {
      const navItem = {
        group: group.fieldValue,
        title: startCase(group.fieldValue),
        subNav: []
      }
      group.edges.forEach(post => {
        navItem.subNav.push({
          title: post.node.frontmatter.short_title,
          slug: post.node.fields.slug,
        })
      })
      navList.push(navItem)    
    });

    return (
      <NavBlogPostTemplate
        siteMetadata={this.props.data.site.siteMetadata}
        location={this.props.location}
        post={post}
        navList={navList} />
    )
  }
}

export default CategoryBlogPost

export const pageQuery = graphql`
  query CategoryBlogPostBySlug($slug: String!, $category: String!) {
    site {
      siteMetadata {
        name
        title
        description
        author
        disqusShortname
        mailchimpEndpoint
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      timeToRead
      body
      fields {
        slug
      }
      frontmatter {
        title
        author
        description
        date(formatString: "MMMM DD, YYYY")
        categories
        group
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 960)
          }
        }
      }
    }
    groupsMdx: allMdx(
      sort: { fields: [frontmatter___group_weight], order: ASC },
      filter: { 
        frontmatter: { 
          status: { ne: "draft" }
          categories: { in: [$category] }
        } 
      } 
    ) {
      group(field: frontmatter___group) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              short_title
            }
          }
        }
      }
    } 
  }
`
